var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "我的", name: "my" } },
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "作业票清单",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-remove": _vm.handleDelete,
                  "head-add": _vm.headAdd,
                },
              }),
              _c("grid-head-layout", {
                ref: "gridHeadLayoutMy",
                attrs: { "search-columns": _vm.searchColumns },
                on: {
                  "grid-head-search": _vm.gridHeadSearch,
                  "grid-head-empty": _vm.gridHeadEmpty,
                },
              }),
              _vm.activeName == "my"
                ? _c("grid-layout", {
                    ref: "gridLayOutMy",
                    attrs: {
                      "table-options": _vm.option,
                      "table-data": _vm.myTableData,
                      "table-loading": _vm.myLoading,
                      "data-total": _vm.myPage.total,
                      "grid-row-btn": _vm.gridRowBtn,
                      page: _vm.myPage,
                    },
                    on: {
                      "grid-row-detail-click": _vm.rowView,
                      "page-current-change": _vm.onLoadMyPage,
                      "page-size-change": _vm.onLoadMyPage,
                      "gird-handle-select-click": _vm.selectionChange,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "customBtn",
                          fn: function ({ row }) {
                            return [
                              row.businessStatus == "DISCLOSED" &&
                              row.characters == "safeConfessor"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "flow")
                                        },
                                      },
                                    },
                                    [_vm._v("安全交底\n            ")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "SUPERVISE" &&
                              row.characters == "supervise" &&
                              row.charactersStatus == "2"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "flow")
                                        },
                                      },
                                    },
                                    [_vm._v("安全监管\n            ")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "CHECKACCEPT" &&
                              row.characters == "respUser"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "flow")
                                        },
                                      },
                                    },
                                    [_vm._v("完工验收\n            ")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "CONFIRMING"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: {
                                        type: "text",
                                        size: "small",
                                        disabled: row.taskId == -1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "sign")
                                        },
                                      },
                                    },
                                    [_vm._v("签署\n            ")]
                                  )
                                : _vm._e(),
                              row.businessStatus == "PREPARE"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.edit(row, "edit")
                                        },
                                      },
                                    },
                                    [_vm._v("编辑\n            ")]
                                  )
                                : _vm._e(),
                              row.businessStatus != "PREPARE"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleProgress(row)
                                        },
                                      },
                                    },
                                    [_vm._v("流程进度\n            ")]
                                  )
                                : _vm._e(),
                              _c(
                                "el-button",
                                {
                                  staticStyle: { margin: "0 3px" },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.rowView(row, "view")
                                    },
                                  },
                                },
                                [_vm._v("查看\n            ")]
                              ),
                              row.businessStatus == "PREPARE"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.rowDel(row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除\n            ")]
                                  )
                                : _vm._e(),
                              row.businessStatus != "CONFIRMING" &&
                              row.businessStatus != "CONFIRMED"
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: { margin: "0 3px" },
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copy(row)
                                        },
                                      },
                                    },
                                    [_vm._v("复制\n            ")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      65054686
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "全部", name: "all" } },
            [
              _c(
                "el-container",
                [
                  _vm.activeName == "all"
                    ? _c("CommonTree", {
                        staticStyle: { "flex-shrink": "0" },
                        attrs: {
                          treeData: _vm.componylist,
                          defaultProps: _vm.defaultProps,
                          searchTitle: _vm.searchTitle,
                          isShowdig: false,
                          showCheckbox: false,
                          "popover-show": false,
                          expandOnClickNode: false,
                          treeTitle: "组织架构",
                        },
                        on: { getNodeClick: _vm.treeNodeClick },
                      })
                    : _vm._e(),
                  _c(
                    "el-main",
                    [
                      _c("head-layout", {
                        attrs: {
                          "head-title": "作业票清单",
                          "head-btn-options": _vm.headBtnOptions,
                        },
                        on: {
                          "head-remove": _vm.handleDelete,
                          "head-add": _vm.headAdd,
                        },
                      }),
                      _c("grid-head-layout", {
                        ref: "gridHeadLayoutAll",
                        attrs: { "search-columns": _vm.searchColumns },
                        on: {
                          "grid-head-search": _vm.gridHeadSearch,
                          "grid-head-empty": _vm.gridHeadEmpty,
                        },
                      }),
                      _vm.activeName == "all"
                        ? _c("grid-layout", {
                            ref: "gridLayOutAll",
                            attrs: {
                              "table-options": _vm.option,
                              "table-data": _vm.tableData,
                              "table-loading": _vm.loading,
                              "data-total": _vm.page.total,
                              "grid-row-btn": _vm.gridRowBtn,
                              page: _vm.page,
                            },
                            on: {
                              "page-current-change": _vm.onLoad,
                              "page-size-change": _vm.onLoad,
                              "grid-row-detail-click": _vm.rowView,
                              "gird-handle-select-click": _vm.selectionChange,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "customBtn",
                                  fn: function ({ row }) {
                                    return [
                                      row.businessStatus != "PREPARE"
                                        ? _c(
                                            "el-button",
                                            {
                                              staticStyle: { margin: "0 3px" },
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleProgress(row)
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "流程进度\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { margin: "0 3px" },
                                          attrs: {
                                            type: "text",
                                            size: "small",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.rowView(row, "view")
                                            },
                                          },
                                        },
                                        [_vm._v("查看\n                ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              965060302
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "作业票类型选择",
            visible: _vm.kictectType,
            width: "940px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.kictectType = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.ticketForm,
                "label-width": "185px",
                rules: _vm.ticketRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "线上申请/线下申请:", prop: "ticketRadio" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.chooseTicket },
                      model: {
                        value: _vm.ticketForm.ticketRadio,
                        callback: function ($$v) {
                          _vm.$set(_vm.ticketForm, "ticketRadio", $$v)
                        },
                        expression: "ticketForm.ticketRadio",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("线上申请"),
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("线下申请和审批"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.ticketForm.ticketRadio == 1,
                      expression: "ticketForm.ticketRadio == 1",
                    },
                  ],
                  attrs: { label: "作业票类型:", prop: "ticketRadio" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择作业票类型" },
                      model: {
                        value: _vm.ticketForm.jobTicketType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ticketForm, "jobTicketType", $$v)
                        },
                        expression: "ticketForm.jobTicketType",
                      },
                    },
                    _vm._l(_vm.dataState, function (item) {
                      return _c("el-option", {
                        key: item.dictKey,
                        attrs: { label: item.dictValue, value: item.dictKey },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.ticketForm.ticketRadio == 1
                ? _c("offlineImg", {
                    ref: "offlineImg",
                    attrs: { disabled: true },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.ticketForm.ticketRadio == 0,
                  expression: "ticketForm.ticketRadio == 0",
                },
              ],
              staticClass: "kicket-content",
            },
            _vm._l(_vm.kictectTypeList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "kicket",
                  on: {
                    click: function ($event) {
                      return _vm.selectKicket(item)
                    },
                  },
                },
                [
                  _c("div", { style: _vm.computedStyle }, [
                    _c("img", {
                      staticClass: "img-item",
                      attrs: { src: item.kictectIcon, alt: "" },
                    }),
                  ]),
                  _c("div", { staticClass: "text-item" }, [
                    _vm._v(_vm._s(item.dictValue)),
                  ]),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitTicket } },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.defBtn")) + "\n        ")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.kictectType = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("cip.cmn.btn.celBtn")) + "\n        ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.bpmnVisible
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "70%",
                customClass: "wf-dialog",
                dialogTitle: _vm.$t(`cip.desk.workflow.title.indexHeadVisible`),
                showBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.bpmnVisible = false
                },
              },
            },
            [
              _c("wf-design", {
                ref: "bpmn",
                staticStyle: { height: "60vh" },
                attrs: { options: _vm.bpmnOption },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.circulationVisible
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "35%",
                dialogTitle: _vm.$t(
                  `cip.plat.wfOps.process.title.processProgressTitle`
                ),
                showBtn: false,
              },
              on: {
                cancel: function ($event) {
                  _vm.circulationVisible = false
                },
              },
            },
            [_c("wf-flow", { attrs: { flow: _vm.flow } })],
            1
          )
        : _vm._e(),
      _c("process-user-dialog", {
        ref: "processUser",
        on: { getUserSelection: _vm.handleUserSelection },
      }),
      _vm.processVisible
        ? _c(
            "CommonDialog",
            {
              attrs: { width: "70%", dialogTitle: "流程进度", showBtn: false },
              on: {
                cancel: function ($event) {
                  _vm.processVisible = false
                },
              },
            },
            [
              _c("bpmn-flow", {
                ref: "bpmnFlow",
                attrs: { bpmnOption: _vm.bpmnOption, flow: _vm.flow },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }